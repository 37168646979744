<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vona-logo />
        <h2 class="brand-text text-primary ml-1">
          {{ getAssociation }}
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Forgot password V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-2">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1"> Confirme o código 🔒 </b-card-title>
          <b-card-text class="mb-2">
            Informe o código de reset de senha enviado por e-mail
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <av-input-confirm-code v-model="code" />
              <av-button
                :loading="loading"
                type="submit"
                variant="primary"
                block
              >
                Confirmar
              </av-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{ name: 'auth-login', query: $route.query }">
              <feather-icon icon="ChevronLeftIcon" /> Voltar para o login
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VonaLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";
import { AvInputConfirmCode, AvButton } from "@/components";
import { required, email } from "@validations";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    VonaLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    AvButton,
    ValidationProvider,
    ValidationObserver,
    AvInputConfirmCode,
  },
  data() {
    return {
      loading: false,
      code: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/forgot-password-v2.svg"),
      // validation
      required,
      email,
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/forgot-password-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
    getAssociation() {
      return localStorage.getItem("domain") || "Vona";
    },
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.loading = true;
          this.$store
            .dispatch("admin-users/fetchValidateCode", {
              email: this.$store.state["admin-users"].recoverPassword.email,
              codigo: this.code,
            })
            .then(() => {
              this.$store.commit("admin-users/SET_CODE_RECOVER", this.code);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Código validado com sucesso",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              this.$router.push({
                name: "auth-reset-password-v1",
                query: this.$route.query,
              });
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Código inválido",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
